import React, {
    Suspense, lazy,
} from 'react';

import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { Switch, Route } from 'react-router-dom';

import StageIndicator from '~/components/StageIndicator';
import useDidMount from '~/hooks/use-did-mount';
import thunks from '~/redux/thunks';
import { renderRoute } from '~/routes/manager';
import { allRoutes } from '~/routes/public';

const NotFoundPage = lazy(() => import('~/domains/others/NotFoundPage'));

const hideSplashScreen = () => {
    const splashScreen = document.getElementById('splash-screen');
    if (splashScreen) {
        setTimeout(() => {
            splashScreen.classList.add('splash-screen-hide');
            setTimeout(
                () => splashScreen.parentNode.removeChild(splashScreen),
                1000,
            );
        }, 2000);
    }
};

const Layout = ({ loadAuthToken, removeAuth }) => {
    const { t } = useTranslation('LAYOUT');

    useDidMount(() => {
        hideSplashScreen();
    });

    return (
        <Suspense fallback={<div>{t('LOADING')}</div>}>
            <Switch>
                {allRoutes.map((route, index) => {
                    return renderRoute(route, index);
                })}
                <Route component={NotFoundPage} />
            </Switch>
            <StageIndicator />
        </Suspense>
    );
};

const mapDispatchToProps = ({
    loadAuthToken: thunks.auth.thunkLoadAuthToken,
    removeAuth: thunks.auth.thunkRemoveAuth,
});

export default connect(null, mapDispatchToProps)(Layout);
