import { combineReducers } from 'redux';

import * as auth from './auth';

export const reducers = combineReducers({
    auth: auth.reducers,
});

export const actions = {
    auth: auth.actions,
};
