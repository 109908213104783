
import UserPreferences from '~/helpers/user-preferences-helper';

const AUTH_TOKEN = 'AUTH/TOKEN';

export const thunkJustRemoveToken = () => {
    return async (dispatch, getState) => {
        return UserPreferences.removeItem(AUTH_TOKEN);
    };
};
