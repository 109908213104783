import React, { memo, useEffect } from 'react';

import { ApolloProvider } from '@apollo/client';
import { ConfigProvider } from 'antd';
import moment from 'moment-timezone';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { IconContext } from 'react-icons';
import { Provider as ReduxProvider } from 'react-redux';
import { Router } from 'react-router-dom';
import { PersistGate } from 'redux-persist/integration/react';

import { apolloClient } from '~/helpers/graphql-helper';
import { history } from '~/helpers/history-helper';
import { AntLocale } from '~/helpers/locale-helper';
import store, { persistor } from '~/redux/store';

const ICON_PROP_VALUE = { className: 'react-icons' };

const Providers = memo(({ children }) => {
    const { i18n } = useTranslation();

    useEffect(() => {
        moment.locale(i18n.language);
    }, [i18n.language]);

    return (
        <ApolloProvider client={apolloClient}>
            <IconContext.Provider value={ICON_PROP_VALUE}>
                <ConfigProvider locale={AntLocale[i18n.language]}>
                    <Router history={history}>
                        <ReduxProvider store={store}>
                            <PersistGate persistor={persistor}>
                                {children}
                            </PersistGate>
                        </ReduxProvider>
                    </Router>
                </ConfigProvider>
            </IconContext.Provider>
        </ApolloProvider>
    );
});

Providers.propTypes = {
    children: PropTypes.node.isRequired,
};

export default Providers;
