import { combineReducers } from 'redux';
import { createAction, handleAction } from 'redux-actions';

const setToken = createAction('AUTH/SET_TOKEN');

export const actions = {
    setToken,
};

export const tokenHandler = handleAction(
    setToken,
    (state, action) => action.payload,
    null,
);

export const reducers = combineReducers({
    token: tokenHandler,
});
