import React from 'react';

import { Redirect, Route } from 'react-router-dom';
import urlJoin from 'url-join';

import store from '~/redux/store';

export const getRoutesArray = route => {
    if (route.group) {
        return route.routes.reduce((result, r) => {
            return [...result, ...getRoutesArray(r)];
        }, []);
    }
    return [route];
};

export const renderRoute = (route, index, prefix) => {
    const { component: Comp } = route;
    const path = prefix ? urlJoin(prefix, route.path) : route.path;

    let component;
    if (route.redirect) {
        const redirectTo = prefix ? urlJoin(prefix, route.redirect) : route.redirect;
        component = () => (
            <Redirect
                to={redirectTo}
            />
        );
    } else {
        component = Comp;
    }

    return (
        <Route
            key={path || index}
            path={path}
            exact={route.exact}
            component={component}
        />
    );
};

export const findParentGroups = (routeThree, route) => {
    const path = [];
    const getPath = (root, dest) => {
        if (root === dest) return true;
        if (root.group && root.routes.some(r => getPath(r, dest))) {
            path.push(root);
            return true;
        }
        return false;
    };
    getPath({
        group: 'root',
        routes: routeThree,
    }, route);
    return path;
};

export const hasRoutePermission = route => {
    if (!Array.isArray(route.permissions)) {
        return true;
    }
    return route.permissions.some(permission => {
        const { can, an } = permission;
        return store.getState().ability.can(can, an);
    });
};
