import enUS from 'antd/lib/locale-provider/en_US';
import esES from 'antd/lib/locale-provider/es_ES';
import ptBR from 'antd/lib/locale-provider/pt_BR';

export const AntLocale = {
    pt: {
        ...ptBR,
        Pagination: {
            ...ptBR.Pagination,
            items_per_page: 'por página',
        },
        Table: {
            ...ptBR.Table,
            filterReset: 'Limpar',
            sortTitle: 'Clique para ordernar',
        },
    },
    en: enUS,
    es: esES,
};

export const AVAILABLE_LANGUAGES = ['en', 'es', 'pt'];

export const findBestAvailableLanguage = languageBrowser => {
    return AVAILABLE_LANGUAGES.find(language => new RegExp(`^${language}(-[a-z]+)?$`, 'i').test(languageBrowser));
};
