import {
    ApolloClient, ApolloLink, concat, HttpLink, InMemoryCache,
} from '@apollo/client';

import store from '~/redux/store';

const { REACT_APP_URL_GRAPHQL } = process.env;

const httpLink = new HttpLink({ uri: REACT_APP_URL_GRAPHQL });

const authMiddleware = new ApolloLink((operation, forward) => {
    const { auth } = store.getState();

    operation.setContext({
        headers: {
            Authorization: auth.token ? `Bearer ${auth.token}` : null,
        },
    });

    return forward(operation);
});

export const apolloClient = new ApolloClient({
    cache: new InMemoryCache(),
    link: concat(authMiddleware, httpLink),
});
