import en from './en.json';
import es from './es.json';
import pt from './pt.json';
import ru from './ru.json';

export default {
    en,
    es,
    pt,
    ru,
};
