import moment from 'moment-timezone';

import store from '~/redux/store';

export function tokenInterceptor(request) {
    const { auth } = store.getState();
    if (auth?.token && !request.headers.token) {
        request.headers.Authorization = `Bearer ${auth?.token}`;
    }
    request.headers.timezone = moment.tz.guess();
    return request;
}
