import {
    createStore, applyMiddleware, compose,
} from 'redux';
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import thunk from 'redux-thunk';

import { reducers } from './ducks';

const persistConfig = {
    key: 'root',
    storage,
    whitelist: ['checkout'],
};

const mainReducer = (state, action) => {
    return reducers(state, action);
};

const allReducers = persistReducer(persistConfig, mainReducer);

const composeEnhancers = __DEV__ && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
    ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
    : compose;

const store = createStore(
    allReducers,
    composeEnhancers(
        applyMiddleware(thunk),
    ),
);

if (process.env.NODE_ENV !== 'production') {
    if (module.hot) {
        module.hot.accept('./ducks', () => {
            store.replaceReducer(allReducers);
        });
    }
}

export const persistor = persistStore(store);

export default store;
