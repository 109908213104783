import { lazy } from 'react';

import { getRoutesArray } from './manager';

const ErrorPage = lazy(() => import('~/domains/others/ErrorPage'));
const EmailConfirmationPage = lazy(() => import('~/domains/authentication/EmailConfirmationPage'));
const ResetPasswordPage = lazy(() => import('~/domains/authentication/ResetPasswordPage'));

const ROUTES = [
    {
        path: '/error',
        component: ErrorPage,
    },
    {
        path: '/redefinicao-senha',
        component: ResetPasswordPage,
    },
    {
        path: '/confirmacao-email',
        component: EmailConfirmationPage,
    },
];

export default ROUTES;

export const allRoutes = getRoutesArray({
    group: 'root',
    routes: ROUTES,
});
